import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import Home from "@/views/Home.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/admin/AdminCategory',
        name: 'AdminCategory',
        component: () => import('../views/admin/AdminCategory.vue')

    },
    {
        path: '/admin/AdminWxSpecialPage',
        name: 'AdminWxSpecialPage',
        component: () => import('../views/admin/AdminWxSpecialPage.vue')

    },
    {
        path: '/admin/AdminWxQuestion',
        name: 'AdminWxQuestion',
        component: () => import('../views/admin/AdminWxQuestion.vue')

    },
    {
        path: '/admin/AdminUsers',
        name: 'AdminUsers',
        component: () => import('../views/admin/AdminUsers.vue')

    },
    {
        path: '/admin/AdminConfig',
        name: 'AdminConfig',
        component: () => import('../views/admin/AdminConfig.vue')

    },
    {
        path: '/admin/Admin',
        name: 'Admin',
        component: () => import('../views/admin/Admin.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const adminPages = ["AdminDownload", "AdminCourse", "AdminCategory",
    "AdminColumn", "AdminQuestion", "AdminWxQuestion", "AdminWxSpecialPage", "AdminUsers", "AdminConfig"];

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // 支付页面
    if (to.name === 'Paying' && from.name !== 'Pay') {
        // 如果目标页面是TargetPage，但来源页面不是SourcePage，则重定向到来源页面
        next({name: 'Pay'});
        return;
    }

    // 管理页面
    if (adminPages.includes(String(to.name)) && from.name === 'Admin' && from.name === to.name) {
        next();
        return;
    }

    // 其他页面通通放行
    next();
});

export default router
